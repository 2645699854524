import React from 'react';
import './DividerBar.css';

interface DividerBarProps {
    text: string
}

const DividerBar: React.FC<DividerBarProps> = ({text}) => {
    return (
        <div>
            <div className="divider-line"><h6>{text}</h6></div>
        </div>
    );
}

export default DividerBar;
