export class TimelineEvent {
    private cacheDate: Date
    /**
   * Creates a new TimelineEvent.
   * @param type
   * @param year
   * @param month
   * @param day
   * @param title
   * @param description
   */
    constructor(
        public type: TimelineEventType,
        public year: number,
        public month?: number,
        public day?: number,
        public title?: string,
        public description?: string
    ) { 
        this.cacheDate = this.getDate()
    }

    public getDate(): Date {
        if (this.cacheDate) {
            return this.cacheDate
        }

        var date = new Date()
        date.setFullYear(this.year)
        if (this.month) {
            date.setMonth(this.month)
        }
        if (this.day) {
            date.setDate(this.day)
        }

        return date
    }

    public compare(right: TimelineEvent): number {
        const leftDate = this.getDate()
        const rightDate = right.getDate()
        if (leftDate === rightDate) {
            return 0;
        }
      
        return (leftDate > rightDate) ? 1 : -1
    }
}

export enum TimelineEventType {
    BIRTH = "født",
    MOVEMENT = "bevægelse",
    FILM = "film",
    FURNITURE = "møbler",
    DRAWING = "tegning",
    DEATH = "død",
    BOOK = "bog",
    MELODI = "melodi",
    SYMPHONY = "symfoni",
    PAINTING = "maleri",
    PLAY = "styk"
}
