import { createSlice } from '@reduxjs/toolkit';
import GuideFaktaArk24 from '../data/guide-faktaark-24.json';
import { Guide } from '../models/Guide';
import { TimelineGuide } from '../models/TimelineGuide';

// Define an initial state
interface GuideDataState {
    data: Map<string, Guide>;
    loading: boolean;
    error: string | null;
}

const initialState: GuideDataState = {
    data: new Map<string, Guide>([
        ["fakta-ark-24", TimelineGuide.fromObject(GuideFaktaArk24)]
    ]),
    loading: false,
    error: null
};

// Create a guide slice with reducers for loading data
const GuideDataSlice = createSlice({
    name: "guides",
    initialState,
    reducers: {},
});

export default GuideDataSlice.reducer;
