import { createSlice } from '@reduxjs/toolkit';
import { Quiz } from '../models/Quiz';

import Quiz2023Sommer from '../data/2023-sommer.json';
import Quiz2022Winter from '../data/2022-vinter.json';
import Quiz2022Sommer from '../data/2022-sommer.json';
import Quiz2021Winter from '../data/2021-vinter.json';
// import Quiz2020Winter from '../data/2020-vinter.json';
import Quiz2020Sommer from '../data/2020-sommer.json';
import Quiz2019Winter from '../data/2019-vinter.json';
import Quiz2019Sommer from '../data/2019-sommer.json';
import Quiz2018Winter from '../data/2018-vinter.json';
import Quiz2018Sommer from '../data/2018-sommer.json';
import Quiz2017Winter from '../data/2017-vinter.json';
import Quiz2017Sommer from '../data/2017-sommer.json';
import Quiz2016Winter from '../data/2016-vinter.json';


// Define an initial state
interface QuizDataState {
    data: Quiz[];
    loading: boolean;
    error: string | null;
}

const initialState: QuizDataState = {
    data: [
        Quiz2023Sommer as Quiz,
        Quiz2022Winter as Quiz,
        Quiz2022Sommer as Quiz,
        Quiz2021Winter as Quiz,
        // Quiz2020Winter as Quiz,
        Quiz2020Sommer as Quiz,
        Quiz2019Winter as Quiz,
        Quiz2019Sommer as Quiz,
        Quiz2018Winter as Quiz,
        Quiz2018Sommer as Quiz,
        Quiz2017Winter as Quiz,
        Quiz2017Sommer as Quiz,
        Quiz2016Winter as Quiz
    ],
    loading: false,
    error: null
};

// Create a quiz slice with reducers for loading data
const QuizDataSlice = createSlice({
    name: "quizzes",
    initialState,
    reducers: {},
});

export default QuizDataSlice.reducer;
