import React, { useEffect, useState } from 'react';
import QuizResults from '../components/QuizResults';
import { useSelector } from 'react-redux';
import { RootState } from '../store/Store';
import { Breadcrumb, Container } from 'react-bootstrap';
import QuizResultsProgressbar from '../components/QuizResultsProgressbar';

const QuizResultsPage: React.FC = () => {
  const quizData = useSelector((state: RootState) => state.quiz);

  const [totalCorrect, setTotalCorrect] = useState<number>(0);
  const [totalQuestions, setTotalQuestions] = useState<number>(0);
  const minimumCorrect = 20;

  useEffect(() => {
    window.scrollTo(0, 0);
    setTotalQuestions(0)
    setTotalCorrect(0)
    for (const index in quizData.questions) {
      setTotalQuestions(prevCount => prevCount + 1)

      const question = quizData.questions;
      if (question[index].answer === quizData.userAnswers[index]) {
        setTotalCorrect(prevCount => prevCount + 1)
      }
    }
  }, [quizData]);

  return (
    <div className='PageContainer'>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/proever">Prøver</Breadcrumb.Item>
        <Breadcrumb.Item active>Resulter</Breadcrumb.Item>
      </Breadcrumb>
      <Container>
        <QuizResultsProgressbar total={totalQuestions} totalCorrect={totalCorrect} minCorrect={minimumCorrect} />
        <Container>
          <h1>{totalCorrect >= minimumCorrect ? "Tilykke. Du har bestået." : "Desværre. Du har ikke bestået."}</h1>
        </Container>
        <QuizResults data={quizData} />
      </Container>
    </div>
  );
};

export default QuizResultsPage;
