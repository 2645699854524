import React, { FC } from 'react';
import { Badge, Card } from 'react-bootstrap';
import { TimelineEventWithPerson } from '../models/TimelineGuide';
import { TimelineEventType } from '../models/TimelineEvent';
import { Palette, MusicNote, VectorPen, Film } from 'react-bootstrap-icons';
import { ImBook } from "react-icons/im";
import { FaMasksTheater, FaBaby } from "react-icons/fa6";
import { GiDeathSkull } from "react-icons/gi";
import { PiArmchairFill } from "react-icons/pi";
import { TbChairDirector } from "react-icons/tb";
import './TimelineCardPerson.css';

interface TimelineCardPersonProps {
    pos: string
    data: TimelineEventWithPerson
    color: string
}
const TimelineEventTypeToIconMap = new Map<TimelineEventType,any>([
    [TimelineEventType.BIRTH, <FaBaby className="CardIcon" />],
    [TimelineEventType.BOOK, <ImBook className="CardIcon" />],
    [TimelineEventType.DEATH, <GiDeathSkull className="CardIcon" />],
    [TimelineEventType.DRAWING, <VectorPen className="CardIcon" />],
    [TimelineEventType.FILM, <Film className="CardIcon" />],
    [TimelineEventType.FURNITURE, <PiArmchairFill className="CardIcon" />],
    [TimelineEventType.MELODI, <MusicNote className="CardIcon" />],
    [TimelineEventType.PAINTING, <Palette className="CardIcon" />],
    [TimelineEventType.SYMPHONY, <MusicNote className="CardIcon" />],
    [TimelineEventType.PLAY, <FaMasksTheater className="CardIcon" />],
    [TimelineEventType.MOVEMENT, <TbChairDirector className="CardIcon" />]
])

const TimelineCardPerson: FC<TimelineCardPersonProps> = ({ data, pos = "right", color = "red" }) => {
    return (
        <div className={`timeline timeline-${color} ${pos} ${pos}-${color}`}>
            <Card className={`TimelineCard gradient-${color}`}>
                <Card.Body>
                    {TimelineEventTypeToIconMap.get(data.event.type)}
                    <Card.Title className="TimelineCardTitle">{data.event.year}</Card.Title>
                    <Card.Subtitle className="TimelineCardSubtitle">{data.person.getFullName()}</Card.Subtitle>
                    <Card.Text className="TimelineCardText">
                        {data.event.title ?? data.event.description} {data.event.type === TimelineEventType.DEATH ? `Død ${data.person.getAge()} år gammel` : ""}
                    </Card.Text>
                    <Badge className="TimelineCardBadge">{data.person.type}</Badge> <Badge className="TimelineCardBadge">{data.event.type}</Badge>
                </Card.Body>
            </Card>
        </div>
    );
};

export default TimelineCardPerson;
