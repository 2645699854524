import React from 'react';
import './GuidePersonTimeline.css';
import { TimelineEventWithPerson, TimelineGuide } from '../models/TimelineGuide';
import { Badge, Container } from 'react-bootstrap';
import TimelineCardPerson from './TimelineCardPerson';
import DividerBar from './DividerBar';

interface GuidePersonTimelineProps {
    guide: TimelineGuide
}

const SupportedColors = ["red", "purple", "blue", "yellow", "green"]

const GuidePersonTimeline: React.FC<GuidePersonTimelineProps> = ({ guide }) => {
    const data = guide?.getMapSortedEvents() ?? new Map<number, TimelineEventWithPerson>()
    let cardCount = -1
    let uniquePeople = new Array<string>()

    return (
        <Container fluid>
            <DividerBar text={guide.title} />
            <div className="main-timeline">
                {[...data.entries()].map(([key, value]) => {
                    return (
                        <span>
                            <Badge className="TimelineYear">{key}</Badge>
                            {
                                value.map(event => {
                                    cardCount++
                                    let index = uniquePeople.findIndex((name) => name === event.person.getFullName())
                                    if (index === -1) {
                                        index = uniquePeople.length
                                        uniquePeople.push(event.person.getFullName())
                                    }
                                    const colorIndex = index % 5
                                    return (
                                        <TimelineCardPerson key={cardCount} data={event} pos={cardCount % 2 === 0 ? "left" : "right"} color={SupportedColors[colorIndex]} />
                                    )
                                })
                            }
                        </span>
                    )
                })}
            </div>
        </Container>
    );
}

export default GuidePersonTimeline;
