import React from 'react';
import { Breadcrumb, Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../store/Store';
import { Quiz } from '../models/Quiz';
import { Link } from 'react-router-dom';
import './QuizSelectionPage.css';
import DividerBar from '../components/DividerBar';

const QuizSelectionPage: React.FC = () => {
    const quizzesData = useSelector((state: RootState) => state.quizzes.data);

    return (
        <div className='PageContainer'>
            <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Prøver</Breadcrumb.Item>
            </Breadcrumb>
            <DividerBar text="Medborgerskabsprøver" />
            <Container>
                <Row>
                    {quizzesData && quizzesData.map((quiz: Quiz, index: number) => (

                        <Col key={index} md={4}>
                            <Card className="SelectionCard">
                                <Card.Body>
                                    <Card.Title>{quiz.title}</Card.Title>
                                    <Card.Text>
                                        
                                    </Card.Text>
                                    <Link to={`/proever/start?id=${quiz.id}`}>
                                        <Button className="QuizStartButton">Start</Button>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default QuizSelectionPage;
