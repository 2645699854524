import { configureStore } from '@reduxjs/toolkit';
import QuizReducer from './QuizSlice';
import QuizDataReducer from './QuizDataSlice';
import GuideDataReducer from './GuideDataSlice';

const Store = configureStore({
    reducer: {
        quiz: QuizReducer,
        quizzes: QuizDataReducer,
        guides: GuideDataReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootDispatch = typeof Store.dispatch;
export type RootState = ReturnType<typeof Store.getState>;
export default Store;
