import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { selectAnswer } from '../store/QuizSlice';
import QuestionCard from './QuestionCard';
import { Question } from '../models/Question';
import { RootDispatch } from '../store/Store';
import { Button, Container } from 'react-bootstrap';
import { Quiz } from '../models/Quiz';
import { Link } from 'react-router-dom';
import './QuizTemplate.css';


interface QuizTemplateProps { data: Quiz | null }

const QuizTemplate: FC<QuizTemplateProps> = ({data}) => {
  const dispatch = useDispatch<RootDispatch>();

  const handleAnswerClick = (questionIndex: number, selectedOptionId: number) => {
    dispatch(selectAnswer({questionIndex, selectedOptionId}));
  };

  return (
    <Container>
      {data && data.questions.length > 0 && data.questions.map((question: Question, index: number) => (
        <QuestionCard
          key={index}
          index={index}
          data={question}
          handleAnswerClick={handleAnswerClick}
        />
      ))}
      <Container className="QuizSubmitButtonContainer">
        <Link to="/proever/resulter">
          <Button className="QuizSubmitButton">Submit</Button>
        </Link>
      </Container>
    </Container>
  );
};

export default QuizTemplate;
