import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Question } from '../models/Question';
import { Quiz } from '../models/Quiz';

export interface QuizState {
    name: string
    questions: Question[];
    userAnswers: number[];
}

const initialState: QuizState = {
    name: "Unnamed Quiz",
    questions: [],
    userAnswers: [],
};

interface SelectedAnswer {
    questionIndex: number
    selectedOptionId: number
}

const QuizSlice = createSlice({
    name: "quiz",
    initialState,
    reducers: {
        selectAnswer: (state, action: PayloadAction<SelectedAnswer>) => {
            state.userAnswers[action.payload.questionIndex] = action.payload.selectedOptionId;
        },
        initializeFromQuiz: (state, action: PayloadAction<Quiz>) => {
            state.questions = action.payload.questions;
            state.name = action.payload.title;
            state.userAnswers = Array(action.payload.questions.length).fill(-1);
        }
    }
});

export const { selectAnswer, initializeFromQuiz } = QuizSlice.actions;
export default QuizSlice.reducer;
