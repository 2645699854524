import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import logo from '../logo.svg';
import './AppBar.css';
import { Link } from 'react-router-dom';

const AppBar: React.FC = () => {
  return (
    <Navbar bg="light" expand="lg" className="AppBar">
      <Container>
        <Link to="/" className="nav-link">
          <Navbar.Brand>
            <img
              src={logo}
              width="50"
              height="50"
              className="d-inline-block align-top"
              alt="BRIANNAMINISTERIET"
            />
          </Navbar.Brand>
        </Link>
        <Link to="/" className="nav-link">
          <span className="d-flex AppBar-text">brianna.dk<br />HALLØJSAMINISTERIET</span>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav" className="AppBar-tabs">
          <Nav className="ml-auto">
            <Link to="/proever" className="nav-link">
              Prøver
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppBar;
