import React, { FC, useState } from 'react';
import { Question } from '../models/Question';
import './QuestionCard.css';
import { Button } from 'react-bootstrap';

interface QuestionProps {
  index: number
  data: Question
  handleAnswerClick: (questionIndex: number, selectedOptionId: number) => void;
}

const QuestionCard: FC<QuestionProps> = ({index, data, handleAnswerClick }) => {
  const [selectedButton, setSelectedButton] = useState<number>(-1);

  const handleButtonClick = (buttonId: number, questionIndex: number, optionId: number) => {
    if(selectedButton !== buttonId) {
      setSelectedButton(buttonId)
      handleAnswerClick(questionIndex, optionId)
    } else {
      setSelectedButton(-1)
      handleAnswerClick(questionIndex, -1)
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="card-title"><b>Spørgsmål {index + 1}.</b> {data.text}</div>
        <div className="">
          {data.options.map((option, i) => (
            <Button 
              key={i} 
              className={`btn btn-primary w-100 h-100 QuestionCard-button ${selectedButton === i ? "bg-primary text-white" : "bg-white text-dark"}`}
              onClick={() => handleButtonClick(i, index, option.id)}
            >
              {option.text}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuestionCard;
