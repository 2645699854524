import { TimelineEvent, TimelineEventType } from "./TimelineEvent";

export class TimelinePerson {
    cacheAge: number = -1
    /**
   * Creates a new TimelinePerson.
   * @param first_name
   * @param last_name
   * @param type
   * @param events
   */
    constructor(
        public first_name: string,
        public last_name: string,
        public type: string,
        public events: TimelineEvent[]
    ) { }

    public getFullName(): string {
        return `${this.first_name} ${this.last_name}`
    }

    public getAge(): number {
        if (this.cacheAge > -1) {
            return this.cacheAge
        }

        let birthdate: number = Date.now()
        let deathdate: number = Date.now()
        for (const event of this.events) {
            if (event.type === TimelineEventType.BIRTH) {
                birthdate = event.getDate().getTime()
            } else if (event.type === TimelineEventType.DEATH) {
                deathdate = event.getDate().getTime()
            }
        }

        this.cacheAge = Math.abs(new Date(deathdate - birthdate).getFullYear() - 1970)
        return this.cacheAge
    }

    public static fromObject(obj: any): TimelinePerson {
        let events = new Array<TimelineEvent>()
        for (const event of obj.events) {
            const type: TimelineEventType = event.type as TimelineEventType
            events.push(new TimelineEvent(type, event.year, event.month, event.day, event.title, event.description))
        }
        return new TimelinePerson(obj.first_name, obj.last_name, obj.type, events)
    }
}
