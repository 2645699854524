import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../store/Store';
import './GuidePage.css';
import { Guide } from '../models/Guide';
import { useSearchParams } from 'react-router-dom';
import GuidePersonTimeline from '../components/GuidePersonTimeline';
import { TimelineGuide } from '../models/TimelineGuide';

interface GuidePageProps { }

const GuidePage: React.FC<GuidePageProps> = () => {
    const guidesData: Map<string, Guide> = useSelector((state: RootState) => state.guides.data);

    const [searchParams] = useSearchParams();
    const paramGuideId = searchParams.get('id');
    
    const [guide, setGuide] = useState<Guide | null>(null);
    
    useEffect(() => {
        // TODO: set id here instead of hardcode
        setGuide(guidesData?.get("fakta-ark-24") ?? null);
    }, [paramGuideId, guidesData]);

    return (
        <div className="PageContainer">
            <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Guider</Breadcrumb.Item>
                <Breadcrumb.Item active>{guide?.title}</Breadcrumb.Item>
            </Breadcrumb>
            {
                guide !== undefined && guide !== null &&
                    <GuidePersonTimeline guide={guide as TimelineGuide} />
            }
            
        </div>
    );
};

export default GuidePage;
