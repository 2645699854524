import React from 'react';
import './AppFooter.css';
import { Github, Instagram, Linkedin } from 'react-bootstrap-icons';

const AppFooter: React.FC = () => {
    return (
        <footer className="bg-white text-muted AppFooter">
            <a href="https://www.linkedin.com/in/bckr/" target="_blank" rel="noopener noreferrer">
                <span className="AppFooterIcon"><Linkedin size={20} /></span>
            </a>
            <a href="https://github.com/bbckr" target="_blank" rel="noopener noreferrer">
                <span className="AppFooterIcon"><Github size={20} /></span>
            </a>
            <a href="https://www.instagram.com/masqiii/" target="_blank" rel="noopener noreferrer">
                <span className="AppFooterIcon"><Instagram size={20} /></span>
            </a>
        </footer>
    );
}

export default AppFooter;
