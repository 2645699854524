import React from 'react';
import './QuizResultsProgressbar.css';
import { Container } from 'react-bootstrap';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

interface QuizResultsProgressbarProps {
    total: number
    totalCorrect: number
    minCorrect: number
}

const QuizResultsProgressbar: React.FC<QuizResultsProgressbarProps> = ({total, totalCorrect, minCorrect}) => {
    return (
        <Container style={{ width: 175, height: 175 }}>
            <CircularProgressbarWithChildren
                value={totalCorrect/total}
                maxValue={1}
                strokeWidth={12}
                styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: totalCorrect >= minCorrect ? "#28a745" : "#dc3545" 
                  })}
                >
                {
                    <Container className="ProgressbarContent">
                        <Container className="ProgressbarContent-title">
                            {totalCorrect}
                        </Container>
                        <Container className="ProgressbarContent-subtitle">
                            ud af {total}
                        </Container>
                    </Container>
                }
            </CircularProgressbarWithChildren>
        </Container>
    );
}

export default QuizResultsProgressbar;
