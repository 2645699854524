import { Question } from "./Question";

const MAX_QUESTIONS_PER_QUIZ = 25;

export class Quiz {
    constructor(
        public id: string,
        public title: string,
        public questions: Question[]
    ) { }

    /**
     * Gets a randomized set of quiz questions across multiple quizzes.
     * @param quizzes The quizzes to source the questions from.
     * @returns A randomized set of questions respecting the quiz question limit.
     */
    static getRandomizedQuestions(quizzes: Quiz[]): Question[] {
        var shuffledQuestions: Question[] = []

        for (const quiz of quizzes) {
            shuffledQuestions = [...shuffledQuestions, ...quiz.questions]
        }

        // Fisher-Yates shuffle algorithm
        for (let i = shuffledQuestions.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1)); // Generate a random index
            // Swap elements at i and j
            [shuffledQuestions[i], shuffledQuestions[j]] = [shuffledQuestions[j], shuffledQuestions[i]];
        }

        var quizLength = shuffledQuestions.length < MAX_QUESTIONS_PER_QUIZ ? shuffledQuestions.length : MAX_QUESTIONS_PER_QUIZ
        return shuffledQuestions.slice(0, quizLength)
    }

    static randomizeQuiz(quiz: Quiz): Quiz {
        return new Quiz(quiz.id, quiz.title, Quiz.getRandomizedQuestions([quiz]));
    }
}
