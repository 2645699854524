export class Guide {
    /**
   * Creates a new Guide.
   * @param title
   * @param type
   */
    constructor(
        public title: string,
        public type: string
    ) { }
}
