import React from 'react';
import { Provider } from 'react-redux';
import './App.css';
import Store from './store/Store';
import AppBar from './components/AppBar';
import { Container } from 'react-bootstrap';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import HomePage from './pages/HomePage';
import QuizSelectionPage from './pages/QuizSelectionPage';
import QuizPage from './pages/QuizPage';
import QuizResultsPage from './pages/QuizResultsPage';
import GuidePage from './pages/GuidePage';
import AppFooter from './components/AppFooter';

const App: React.FC = () => {
  return (
    <Router>
      <Provider store={Store}>
        <div className="App">
          <AppBar />
          <Container style={{ maxWidth: "100%", padding:0 }}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/proever" element={<QuizSelectionPage />} />
              <Route path="/proever/start" element={<QuizPage />} />
              <Route path="/proever/resulter" element={<QuizResultsPage />} />
              <Route path="/guider" element={<GuidePage />} />
            </Routes>
          </Container>
          <AppFooter />
        </div>
      </Provider>
    </Router>
  );
}

export default App;
