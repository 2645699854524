import React from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import './HomePage.css';
import DividerBar from '../components/DividerBar';
import { FaPencil, FaPeopleGroup } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

const HomePage: React.FC = () => {
  return (
    <div className="HomeContainer">
      <Container className="WelcomeContainer">
        <Row>
          <Col><h1>Halløjsa.</h1></Col>
        </Row>
        <Row>
          <Col md={6}><Badge bg="dark">udråbsord</Badge><b> [haˈlʌjsa]</b> bruges for at gøre opmærksom på noget interessant eller pudsigt</Col>
        </Row>
      </Container>
      <Container className="HighlightsContainer">
        <Row className="text-muted">
          <Link to="/proever" className="nav-link">
            <Col md={3}>
              <FaPencil size={35} className="mb-4 c-red" />
              <h1>Gratis Prøver</h1>
              Test din viden om sprog, medborgerskab osv.
            </Col>
          </Link>
        </Row>
      </Container>
      <div className='PageContainer'>
        <DividerBar text="Om HalløjsaMinisteriet" />
        <FaPeopleGroup size={25} className="m-3" />
        <Container>
          Vi er faktisk ikke et rigtigt ministerium, men denne side kan bruges til at udnytte alle de ressourcer og hjælpemidler, jeg har samlet og lavet til hjemmesiden.
          <br /><br />
          Nogle af disse omhandler sprog og integration, og de har været utroligt hjælpsomme på min rejse i Danmark som udlænding.
        </Container>
      </div>
    </div>
  );
};

export default HomePage;
