import React, { FC } from 'react';
import './QuestionCard.css';
import { QuizState } from '../store/QuizSlice';
import { Question } from '../models/Question';
import QuestionResultsCard from './QuestionResultCard';

interface QuizResultsProps {
  data: QuizState
}

const QuizResults: FC<QuizResultsProps> = ({ data }) => {
  return (
    <div>
      {data && data.questions.length > 0 && data.questions.map((question: Question, index: number) => (
        <QuestionResultsCard
          key={index}
          index={index}
          data={question}
          userAnswer={data.userAnswers[index]}
        />
      ))}
    </div>
  );
};

export default QuizResults;
