import React, { useEffect, useState } from 'react';
import QuizTemplate from '../components/QuizTemplate';
import { Breadcrumb } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../store/Store';
import { useSearchParams } from 'react-router-dom';
import { Quiz } from '../models/Quiz';
import { initializeFromQuiz } from '../store/QuizSlice';

interface QuizPageProps { }

const QuizPage: React.FC<QuizPageProps> = () => {
    const dispatch = useDispatch<RootDispatch>();
    const quizzesData = useSelector((state: RootState) => state.quizzes.data);
    
    const [searchParams] = useSearchParams();
    const paramQuizId = searchParams.get('id');
    
    const [quiz, setQuiz] = useState<Quiz | null>(null);
    
    useEffect(() => {
        setQuiz(null);
        for(const q of quizzesData) {
            if (q.id === paramQuizId) {
                const randomizedQuiz = Quiz.randomizeQuiz(q);
                setQuiz(randomizedQuiz);
                dispatch(initializeFromQuiz(randomizedQuiz));
                return;
            }
        }
    }, [dispatch, paramQuizId, quizzesData]);

    return (
        <div className="PageContainer">
            <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/proever">Prøver</Breadcrumb.Item>
                <Breadcrumb.Item active>Medborgerskabsprøven</Breadcrumb.Item>
            </Breadcrumb>
            <QuizTemplate data={quiz} />
        </div>
    );
};

export default QuizPage;
