import { Guide } from "./Guide";
import { TimelineEvent } from "./TimelineEvent";
import { TimelinePerson } from "./TimelinePerson";

export class TimelineGuide extends Guide {
    /**
   * Creates a new TimelineGuide.
   * @param title
   * @param type
   * @param data
   */
    constructor(
        public title: string,
        public type: string,
        public data: TimelinePerson[] = []
    ) {
        super(title, type)
    }

    public getSortedTimelineEventWithPerson(): TimelineEventWithPerson[] {
        let result = new Array<TimelineEventWithPerson>()
        for(const person of this.data) {
            const tewps = person.events.map(event => new TimelineEventWithPerson(person, event))
            result = [...result, ...tewps]
        }
        return result.sort((a, b) => { return a.event.compare(b.event) })
    }

    public getMapSortedEvents(): Map<number, TimelineEventWithPerson[]> {
        let result = new Map<number, TimelineEventWithPerson[]>()
        const sortedTewp = this.getSortedTimelineEventWithPerson()
        for(const tewp of sortedTewp) {
            const key = Math.floor(tewp.event.year/100) * 100 // e.g. 1987 -> 1900
            const value = result.get(key)
            if (value === undefined) {
                result.set(key, [tewp])
            } else {
                result.set(key, [...value, tewp])
            }
        }
        return result;
    }

    public static fromObject(obj: any): TimelineGuide {
        let data = new Array<TimelinePerson>()
        for(const person of obj.data) {
            data.push(TimelinePerson.fromObject(person))
        }
        return new TimelineGuide(obj.title, obj.type, data)
    }
}

export class TimelineEventWithPerson {
    constructor(
        public person: TimelinePerson,
        public event: TimelineEvent
    ) { }
}
