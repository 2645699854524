import React, { FC, useState, useEffect } from 'react';
import { Question } from '../models/Question';
import { Badge, Button } from 'react-bootstrap';

interface QuestionResultCardProps {
    index: number
    data: Question
    userAnswer: number
}

enum QuestionStatus {
    CORRECT = 'CORRECT',
    INCORRECT = 'INCORRECT',
    UNANSWERED = 'UNANSWERED',
  }

const QuestionResultCard: FC<QuestionResultCardProps> = ({ index, data, userAnswer }) => {
    const [questionStatus, setQuestionStatus] = useState<QuestionStatus>(QuestionStatus.UNANSWERED);

    useEffect(() => {
        setQuestionStatus(getQuestionStatus())
    }, []);

    const getResultButtonStyle = (optionId: number) => {
        if (userAnswer === optionId && userAnswer !== data.answer) {
            return "bg-danger text-white"
        }

        if (data.answer !== optionId) {
            return "bg-white text-dark"
        }

        if (userAnswer === -1 || (data.answer === optionId && userAnswer !== optionId)) {
            return "bg-warning text-white"
        }

        return "bg-success text-white"
    }

    const getQuestionStatus = () => {
        if (userAnswer === -1) {
            return QuestionStatus.UNANSWERED;
        }

        if (userAnswer === data.answer) {
            return QuestionStatus.CORRECT;
        }

        return QuestionStatus.INCORRECT;
    }

    const getQuestionStateColor = () => {
        if (questionStatus === QuestionStatus.INCORRECT) {
            return "danger";
        }

        if (questionStatus === QuestionStatus.UNANSWERED) {
            return "warning";
        }

        return "success";
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className="card-title"><b>Spørgsmål {index + 1}.</b> {data.text}</div>
                <div className="card-title"><Badge bg={getQuestionStateColor()}>{questionStatus}</Badge></div>
                <div className="">
                    {data.options.map((option, i) => (
                        <Button
                            key={i}
                            className={`btn btn-primary w-100 h-100 QuestionCard-button ${getResultButtonStyle(option.id)}`}
                        >
                            {option.text}
                        </Button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default QuestionResultCard;
